@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.groupWrp {
  margin-bottom: 20px;
}

.subtitle {
  display: inline-block;
  font-size: 10px;
  text-transform: uppercase;
  color: $secondary3Color;
  line-height: 7px;
  margin-bottom: 15px;
  font-weight: 500;
  letter-spacing: 1px;
}

.checkbox {
  margin-bottom: 10px;

  display: flex;
  align-items: center;

  .label {
    line-height: 20px;
  }
}

.radioGroup {
  display: flex;
  flex-direction: column;
}

.radioButton {
  margin-bottom: 10px;

  letter-spacing: 0.01em;
  line-height: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }
}
